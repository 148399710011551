import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'rd-allowed-characters-popover',
  templateUrl: './allowed-characters-popover.component.html',
  styleUrls: ['./allowed-characters-popover.component.css']
})
export class AllowedCharactersPopoverComponent implements OnInit {
  elem: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.elem = elementRef.nativeElement;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $(this.elem).find('.chars').popover({
      delay: {
        show: 100,
        hide: 1000
      }
    });
  }

}
