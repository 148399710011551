import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'angular2-ui-switch'

import { RdAngularCommonModule } from '@rd/common';
import { RdAngularFormsModule } from '@rd/forms';
import { RdAngularDatetimeModule } from '@rd/datetime';
import { RdAngularSmsModule } from '@rd/sms';

import { AuthGuard } from './auth/auth.guard';
import { PhoneSelectDirective } from './phone-select.directive';
import { DatetimePickerComponent } from './datetime-picker';
import { PagedListComponent, PagerService } from './paged-list';
import { RefreshNowComponent } from './refresh-now';
import { ExpandingBoxComponent } from './expanding-box';
import { DynamicDataTableDirective } from './dynamic-data-table.directive';
import { AllowedCharactersPopoverComponent } from './allowed-characters-popover';
import { FilterPipe } from './filter.pipe';
import { AccountPhoneSelectDirective } from './account-phone-select.directive';
import { MomentFormatPipe } from './moment-format.pipe';
import { BootstrapPopoverDirective } from './bootstrap-popover.directive';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MaskModule } from './mask/mask.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RdAngularCommonModule,
        RdAngularFormsModule,
        RdAngularDatetimeModule,
        RdAngularSmsModule,
        UiSwitchModule,
    ],
    declarations: [
        PhoneSelectDirective,
        AllowedCharactersPopoverComponent,
        PagedListComponent,
        RefreshNowComponent,
        ExpandingBoxComponent,
        DatetimePickerComponent,
        DynamicDataTableDirective,
        FilterPipe,
        AccountPhoneSelectDirective,
        MomentFormatPipe,
        BootstrapPopoverDirective,
        LoadingIndicatorComponent,
    ],
    exports: [
        /* declaration exports */
        PhoneSelectDirective,
        AllowedCharactersPopoverComponent,
        PagedListComponent,
        RefreshNowComponent,
        ExpandingBoxComponent,
        DatetimePickerComponent,
        DynamicDataTableDirective,
        FilterPipe,
        AccountPhoneSelectDirective,
        MomentFormatPipe,
        BootstrapPopoverDirective,
        LoadingIndicatorComponent,

        /* module exports */
        RdAngularCommonModule,
        RdAngularFormsModule,
        RdAngularDatetimeModule,
        RdAngularSmsModule,
        FormsModule,
        ReactiveFormsModule,
        UiSwitchModule,
        MaskModule,
    ],
    providers: [
        PagerService,
    ]
})
export class SharedModule { }
