import { Directive } from '@angular/core';

@Directive({
  selector: '[rdDynamicDataTable]',
  host: {
    '[class.responsive]': 'true',
    '[class.dynamicTable]': 'true',
    '[class.display]': 'true',
    '[class.table]': 'true',
    '[class.table-bordered]': 'true',
    // 'responsive: true, dynamicTable: true, display: true, table: true, table-bordered: true'
  }
})
export class DynamicDataTableDirective {

  constructor() { }

}
