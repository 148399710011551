
import {interval as observableInterval,  Observable } from 'rxjs';

import {takeWhile} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';

@Component({
  selector: 'rd-refresh-now',
  templateUrl: './refresh-now.component.html',
  styleUrls: ['./refresh-now.component.css']
})
export class RefreshNowComponent implements OnInit, AfterViewInit {
  @Input() refreshValue: boolean = true;
  @Input() refreshInterval: number = 60;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  alive: boolean = true;
  timer: any = null;
  timeRemaining: number;

  constructor(private ngZone: NgZone) { }

  ngOnInit() {
    this.timeRemaining = this.refreshInterval;
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.initJquery()
    });
  }

  refreshNowClick(event) {
    let self = this;
    self.refresh.emit(null);
    self.timeRemaining = this.refreshInterval;
    $('#countdownvalue').text(self.timeRemaining.toString());
  }

  initJquery() {
    let self = this;

    $(document).ready(function () {
      $("#progress").hide();
    });

    function Show() {
      $("#progress").fadeIn(500);
    }

    function Hide() {
      $("#progress").fadeOut(500);
      $("#queueRefreshSpan").animate({
        backgroundColor: "transparent",
        color: "#333333"
      }, 2000);
    }

    function setBackColorQueue(secondsLeft) {

      if (secondsLeft == 9) {
        $("#queueRefreshSpan").animate({
          backgroundColor: "#ee5f5b",
          color: "#ffffff"
        }, 2000);
      }

      if (secondsLeft == 1) {

        $("#queueRefreshSpan").animate({
          backgroundColor: "transparent",
          color: "#333333"
        }, 2000);
      }
    }

    function resetTimer() {
      self.timeRemaining = self.refreshInterval;
      $('#countdownvalue').text(self.timeRemaining.toString());
    }

    $(document).ready(function () {
      self.timer = observableInterval(1000).pipe(takeWhile(() => self.alive)).subscribe((elapsed: number) => {
        if (self.refreshValue) {
          self.timeRemaining -= 1;
  
          if (self.timeRemaining <= 0) {
            //Refresh Inbox
            //$('#refreshNowLink').click();
            self.refresh.emit(null);
            self.timeRemaining = self.refreshInterval;
            $('#countdownvalue').text(self.timeRemaining.toString());
          } else {
            if (self.timeRemaining < 10) {
              setBackColorQueue(self.timeRemaining);
              $('#countdownvalue').text("0" + self.timeRemaining.toString());
            } else {
              $('#countdownvalue').text(self.timeRemaining.toString());
            }
  
          }
        }
      });

      $("#autoRefreshLink").bind("click", function () {
        if ($("#autoRefreshLink").text() == "Off") {
          self.refreshValue = false;
          $("#autoRefreshLink").text("On");
          $("#queueRefreshSpan").animate({
            backgroundColor: "transparent",
            color: "#333333"
          }, 2000);
          resetTimer();
        } else {
          $("#autoRefreshLink").text("Off");
          self.refreshValue = true;
        }

        return false;
      });
    });
  }

  ngOnDestroy(){
    this.alive = false;
  }

}
