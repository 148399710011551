import { AfterContentInit, AfterViewInit, Attribute, Component, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

import { Toast } from '@rd/common';
import { NgModelInput, NgModelInputValueAccessor, isBlur } from '@rd/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

let count = 1;

@Component({
  selector: 'rd-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatetimePickerComponent,
    multi: true
}],
})
export class DatetimePickerComponent extends NgModelInput implements OnInit {
  @Input() id: number = count;
  @Input() disabled: boolean = false;
  @Input() ngModel: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  datetimepicker: any = null;
  elem: HTMLElement;

  get inputValue () {
    return $(this.elem).find('input').val();
  }

  constructor(private toast: Toast, elementRef: ElementRef) {
    super();
    this.elem = elementRef.nativeElement;
    count++;
  }
 
  ngOnInit() {

  }

  ngAfterViewInit() {
    let self = this;
    this.datetimepicker = $(this.elem).datetimepicker({
      language: 'en',
      pick12HourFormat: true,
      //debug: true,
    }).on('changeDate', function (event) {
      let newVal = self.inputValue;

      self.setNgModel(newVal);
      
      self.change.emit(newVal);

    }).data('datetimepicker');
  }

  onChange(event: any) {
    this.change.emit(event);
  }

  writeValue(newVal: string) {
    if (this.datetimepicker)
      $(this.elem).datetimepicker('setValue', newVal);
    super.writeValue(newVal);
  }

}
