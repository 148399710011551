import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'txt-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.css']
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() height?: string = '50px';
  @Input() width?: string = '50px';

  constructor() { }

  ngOnInit() {
  }

}
