import { Directive, Input, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

import { TextMsgItApiService } from '@rd/core';

@Directive({
  selector: '[rdAccountPhoneSelect]',
  exportAs: 'rdAccountPhoneSelect'
})
export class AccountPhoneSelectDirective implements OnChanges {
  @Input() accountId: number;

  phones: any[] = [];

  constructor(private textMsgItApiSvc: TextMsgItApiService) { }

  ngOnInit() {
    if (this.accountId)
      this.get();
  }

  get() {
    return this.textMsgItApiSvc.get(`/phones?include=account&filters=account=${this.accountId}`).subscribe(result => this.phones = result);
  }

  ngOnChanges(newVal: SimpleChanges) {
    let accountIdChange: SimpleChange = newVal['accountId'];

    if (accountIdChange && accountIdChange.currentValue && !accountIdChange.isFirstChange())
      return this.get();
  }
}
