import { Component } from '@angular/core';

/**
 * 
 * 
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'rd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  /**
   * 
   */
  title = 'TextMsgIt Admin';
}
