import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CoreAuthService, CoreApiService } from '@rd/core';

@Component({
  selector: 'rd-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {

  user: any = '';
  isSelected: string;
  navTabs: any[] = [];
  activeNavTab: string;

  constructor(public router: Router, private authSvc: CoreAuthService, private rdCoreService: CoreApiService) {}

  ngOnInit() {
    if(this.authSvc.userId){
    this.rdCoreService.get('/users/' + this.authSvc.userId)
      .subscribe((userInfo: any) => {
        this.user = userInfo.firstName + ' ' + userInfo.lastName;
      },(error:any)=>{console.log(error);});
    }
    this.isSelected = this.getIsSelected();
  }

  onSelect(path: string) {
    if (path === '/logout') {
      this.authSvc.logout();
      this.router.navigateByUrl('/auth/login');
    } else {
      this.isSelected = path;
      this.router.navigate([path]).then( () => {
        if (path === '/pm-system') {
          this.navTabs = [{name: 'resman'}, {name: 'yardi'}];
          this.activeNavTab = 'resman';
        } else {
          this.navTabs = [];
        }
      });
    }
  }

  onSelectTab(tabName: string) {
    this.activeNavTab = tabName;
    this.router.navigateByUrl(this.isSelected + '/' + tabName);
  }

  getIsSelected() {
    // TODO: find a better way to find the main route
    // This way breaks when you use the back button (the back button doesn't run ngOnInit)
    // Get current URL
    let url = this.router.url;
    let newUrl = url.split('')[0];
    // Loop through the url and keep returning the letter until you see a '/'
    for (let i = 1; i < url.split('').length; i++) {
      if (url.split('')[i] !== '/') {
        newUrl += url.split('')[i];
      } else {
        break;
      }
    }
    return newUrl;
  }


}
