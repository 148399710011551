import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'rd-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
