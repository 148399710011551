export class RouterStub {

    public url: string = '/client/list';

    navigateByUrl() {
        return null;
    }

    navigate() {
        return new Promise((pass, fail) => { pass(true); });
    }
}
