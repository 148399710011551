import { Directive, OnInit } from '@angular/core';

import { TextMsgItApiService } from '@rd/core';

@Directive({
  selector: '[rdPhoneSelect]',
  exportAs: 'rdPhoneSelect',
})
export class PhoneSelectDirective {

  phones: any[] = [];

  constructor(private textMsgItApiSvc: TextMsgItApiService) { }

  ngOnInit(){
    this.textMsgItApiSvc.get('/phones?include=account').subscribe(result => this.phones = result);
  }

}
