import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { RdAngularCoreModule, CoreAuthServiceConfig, TextMsgItAuthServiceConfig } from '@rd/core';
import { RdAngularCommonModule } from '@rd/common';
import { RdAngularFormsModule } from '@rd/forms';
import { RdAngularDatetimeModule } from '@rd/datetime';

import {
  RdNgCoreAuthServiceConfig, RdNgTextMsgItAuthServiceConfig,
  MainLayoutComponent, LoginLayoutComponent,
  AuthGuard
} from './shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    LoginLayoutComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    RdAngularCoreModule.forRoot(),
    RdAngularCommonModule,
    RdAngularFormsModule,
    RdAngularDatetimeModule.forRoot()
  ],
  providers: [
    AuthGuard,
    { provide: CoreAuthServiceConfig, useClass: RdNgCoreAuthServiceConfig },
    { provide: TextMsgItAuthServiceConfig, useClass: RdNgTextMsgItAuthServiceConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
