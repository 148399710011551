import { Inject, Injectable } from "@angular/core";
import { BaseRequestOptions } from '@angular/http';

import { CoreAuthServiceConfig, TextMsgItAuthServiceConfig } from '@rd/core';

import { environment } from '../../environments/environment';


@Injectable()
export class RdNgCoreAuthServiceConfig implements CoreAuthServiceConfig {
    apiKey: string = 'a4e45129-9';
    authToken: string = '';
    secretKey: string = '13987e39-6566-4329-8';
    userId: string = '';

    constructor() {
        this.userId = window.sessionStorage.getItem('rdUserId');
        this.authToken = window.sessionStorage.getItem('rdUserAuthToken');
    }

    get host() {
        if (environment.production) {
            return '//api.rentdynamics.com';
        }
        return '//api.rentdynamics.dev';
    }
}

@Injectable()
export class RdNgTextMsgItAuthServiceConfig implements TextMsgItAuthServiceConfig {
    apiKey: string = 'cd8ff1df-e';
    authToken: string = null;
    secretKey: string = 'b346c424-2b4b-4a83-b';
    userId: string = null;

    constructor() {

    }

    get host() {
      return `https://${environment.production ? 'api' : 'api-dev'}.textmsgit.com`
    }
}
