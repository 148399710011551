import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rd-expanding-box',
  templateUrl: './expanding-box.component.html',
  styleUrls: ['./expanding-box.component.css']
})
export class ExpandingBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
