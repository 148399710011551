import { Directive, OnInit, Optional, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { NgModel, NgControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { REGEX_MASK } from './regex-mask';

@Directive({
  selector: 'input[rdTextUnmask]'
})
export class TextUnmaskDirective {
  // @Input('rdTextUnmask') unmaskRegex?: string;
  @Output() unmask = new EventEmitter();

  alive: boolean = true;
  readonly REGEX = REGEX_MASK; 

  constructor(private model: NgControl) {

  }

  ngOnInit(){
    
  }

  @HostListener('input') inputChange() {
    this.unmask.emit(this.model);
    // const newVal = this.model.value.replace(new RegExp(this.unmaskRegex), '');
    
    // this.model.control.setValue(newVal, {
    //   emitEvent: true,
    //   emitModelToViewChange: true,
    //   emitViewToModelChange: true
    // }); 
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

