import { NgModule }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { AuthGuard } from './shared/auth/auth.guard';



export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'inbox', pathMatch: 'full' },
            { path: 'inbox', loadChildren: 'src/app/inbox/inbox.module#InboxModule' },
            { path: 'outbox', loadChildren: 'src/app/outbox/outbox.module#OutboxModule' },
            { path: 'few-recipients', loadChildren: 'src/app/few-recipients/few-recipients.module#FewRecipientsModule' },
            { path: 'many-recipients', loadChildren: 'src/app/many-recipients/many-recipients.module#ManyRecipientsModule' },
            { path: 'opt-out', loadChildren: 'src/app/opt-out/opt-out.module#OptOutModule' },
            //{ path: 'campaigns', loadChildren: 'app/campaigns/campaigns.module#CampaignsModule' },
        ]
    },
    {
        path: 'auth',
        component: MainLayoutComponent,
        loadChildren: 'src/app/login/login.module#LoginModule'
    },
    {
      path: 'ping', loadChildren: 'src/app/ping/ping.module#PingModule'
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
