import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PagerService, Pager } from './shared';

export interface PagerParams {
  totalItems: number;
  currentPage: number;
  pageSize: number;
}

@Component({
  selector: 'rd-paged-list',
  templateUrl: './paged-list.component.html',
  styleUrls: ['./paged-list.component.css']
})
export class PagedListComponent implements OnInit {
  @Input() page: number = 1;
  @Input() pageSize: number = 10;
  @Input() totalCount: number = 0;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  pager: Pager;

  constructor(private pagerSvc: PagerService) { }

  ngOnInit() {
    this.pager = this.pagerSvc.getPager(this.totalCount, this.page, this.pageSize);
  }

  ngOnChanges(change: SimpleChanges) {
    let changeTotalCount: SimpleChange = change['totalCount'];
    if (changeTotalCount && changeTotalCount.currentValue != changeTotalCount.previousValue) {
      this.pager = this.pagerSvc.getPager(this.totalCount, this.page, this.pageSize);
    }
  }

  setPage(page: number) {
    this.pager = this.pagerSvc.getPager(this.totalCount, page, this.pageSize);
    this.pageChange.emit(page);
  }
}
