import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, HostBinding, SimpleChange, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[rdBootstrapPopover]'
})
export class BootstrapPopoverDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() upper: string;
  @Input() lower: string;
  @Input() show?: number = 100;
  @Input() hide?: number = 1000;

  @HostBinding('attr.title') get title() {
    return this.upper;
  }

  @HostBinding('attr.data-content') get dataContent() {
    return this.lower;
  }

  private elem: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.elem = elementRef.nativeElement;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.triggerPopover();
  }

  triggerPopover() {
    $(this.elem).popover({
      delay: {
        show: this.show,
        hide: this.hide
      }
    });
  }

  ngOnChanges(newVal: SimpleChanges) {
    let titleChange: SimpleChange = newVal['title'];

    if (titleChange && !titleChange.isFirstChange() && titleChange.currentValue && titleChange.currentValue != titleChange.previousValue)
      this.triggerPopover();
  }
}
